<template>
  <div class="darg-to-dress-game-container layout-border">
    <PageButton
      :bottomCornerButtonList="bottomCornerButtonList"
      :isLastStep="isLastStep"
      @clickNext="nextPage"
    ></PageButton>
    <div class="game-content" :class="'lesson-' + lessonNO">
      <Star
        :totalStarNumber="bgImgList.length"
        :currentIndex="step"
        v-if="hasStar"
      />
      <div class="title-area" v-if="titleInfo">
        <div class="title">
          <div class="title-pinyin pinyin font-pinyin-medium">
            {{ titleInfo.pinyin }}
          </div>
          <div class="title-hanzi font-hanzi-medium">{{ titleInfo.hanzi }}</div>
        </div>
      </div>
      <div class="background-area">
        <img :src="bgImg" class="bg-img" />
        <template v-for="(item, index) in bgImgList">
          <img
            :src="item.image"
            class="bg-img-item"
            :key="index + 'bg'"
            v-if="dropId === item.id || dispearIdList.indexOf(item.id) !== -1"
          />
        </template>
      </div>
      <div
        class="drop-area dropBox"
        draggable="true"
        @drop.stop="handleDropImg($event)"
        @dragover.stop="allowDrop($event)"
      ></div>

      <div class="drag-area">
        <div
          v-for="(item, index) in optionImgList"
          class="option-item"
          :class="'option-' + item.id"
          :key="index + 'drag'"
        >
          <img
            :class="{ notShow: dragId === item.id }"
            v-show="dispearIdList.indexOf(item.id) === -1"
            :src="item.image"
            draggable="true"
            @dragstart.stop="handleDragImg($event, item.id)"
            @drop.stop="Sdrop($event)"
            @dragend.stop="dragend(item.group)"
          />
        </div>
      </div>
      <div
        class="hanzi-item"
        v-for="(item, index) in bgImgList"
        :key="index"
        v-show="showHnaziIndex === index && hasSentence&&item.hanzi"
      >
        <div class="pinyin sentence-hanzi font-pinyin-medium">
          {{ item.pinyin }}
        </div>
        <div class="sentence-hanzi font-hanzi-medium">{{ item.hanzi }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import { startConfetti, playCorrectSound } from "@/utils/tools";
import Star from "@/components/Course/GamePage/Star";
import PageButton from "@/components/Course/Interaction/Button/pageButton.vue";
export default {
  name: "DragToCakeGame",
  props: {
    optionImgList: {
      type: Array,
      require: true,
      default: () => [],
    },
    bgImgList: {
      type: Array,
      require: true,
      default: () => [],
    },
    bgImg: {
      type: String,
      require: true,
    },
    lessonNO: {
      type: Number,
      require: true,
    },
    titleInfo: {
      type: Object,
      require: false,
      default: () => {},
    },
    hasStar: {
      type: Boolean,
      default: true,
    },
    hasSentence: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    PageButton,
    Star,
  },
  data() {
    return {
      clickIndex: 0,
      isLastStep: false,
      showHnaziIndex: false,
      dispearIdList: [],
      groupDroppedList:[],
      dropIdList: [],
      dragId: null,
      dropId: null,
      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true,
        },
        buttons: [
          {
            type: "look",
            startNumberRange: 0,
            endNumberRange: 100,
          },
        ],
      },
      dropImgSocketInfo: {},
      dragImgSocketInfo: {},
      step: 0,
    };
  },
  watch: {
    dropImgSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2005701,
          data: { value },
          text: "DragToGressGame drag socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
    dragImgSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2005702,
          data: { value },
          text: "DragToGressGame drop socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
  },
  mounted() {
    this.$bus.$on("dropImgEvent", () => {
      this.handleDropImg("socket", true);
    });
    this.$bus.$on("dragImgEvent", ({ id }) => {
      this.handleDragImg("socket", id, true);
    });
  },
  beforeDestroy() {
    this.$bus.$off("dropImgEvent");
    this.$bus.$off("dragImgEvent");
  },
  methods: {
    nextPage() {
      this.$bus.$emit("nextButton", true);
    },
    handleDragImg(event, id, isFromSocket = false) {
      if (!isFromSocket) {
        this.dragImgSocketInfo = {
          id,
          value: Math.random(),
        };
      }
      this.dragId = id;
      if (this.dragId === id) {
        console.log("拖拽中");
      }

    },
    handleDropImg(event, isFromSocket = false) {
      console.log("拖拽成功");
      if (!isFromSocket) {
        this.dropImgSocketInfo = {
          value: Math.random(),
        };
      }
      // this.dispearIdList = [];
      this.dispearIdList.push(this.dragId);
      this.categoryDropped.push(this.optionImgList[this.dragId].group);
      this.dropId = this.dragId;
      if (this.lessonNO === 21) {
        playCorrectSound();
        startConfetti();
        this.isLastStep = true;
      } else {
        if (this.hasSentence) {
          this.showHnaziIndex = this.dropId - 1;
        }
        playCorrectSound(true, false);
        if (this.dropIdList.indexOf(this.dropId) !== -1) {
          return;
        }
        this.dropIdList.push(this.dropId);
        this.step++;
        if (this.dropIdList.length === this.bgImgList.length) {
          playCorrectSound();
          startConfetti();
          this.isLastStep = true;
        }
      }
    },
    Sdrop(event) {
      event.preventDefault();
    },
    allowDrop(event) {
      event.preventDefault();
    },
    dragend(itemGroup = null) {
      if (itemGroup !== null) {
        if (this.groupDroppedList[itemGroup] !== undefined) {
          // the format is : array(<groupID> => <itemId>, ...)
          // this means another body part from the same type is already on the body
          // remove the previous one

          // get the item that is already used for this group
          let usedItemFromCurrentGroup = this.groupDroppedList[itemGroup];
          this.dispearIdList[this.dispearIdList.indexOf(usedItemFromCurrentGroup)] = null;

        } 
        this.groupDroppedList[itemGroup] = this.dragId;
      } else {
          this.dispearIdList = [this.dragId];
      }
      this.dragId = null;
    },
  },
};
</script>
<style lang="scss" scoped>
.option-List {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
}
.title-area {
  z-index: 11;
  position: absolute;
  left: 0;
  top: 10%;
  width: 20%;
  height: 17%;
  background: #cd4c3f;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 22px;
  color: #fff;
  .title {
    .title-pinyin,
    .title-hanzi {
      text-align: center;
    }
  }
}
.darg-to-dress-game-container {
  background: #fff;
  border-radius: 48px;
  position: relative;
  .game-content {
    width: 100%;
    height: 100%;
    position: relative;
    .background-area {
      width: 100%;
      height: 100%;
      position: relative;
      //   width: fit-content;
      .bg-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 58px;
      }
      .bg-img-item {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 58px;
      }
    }
    .drop-area {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }

    .drag-area {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      img {
        cursor: pointer;
        width: 90%;
        -webkit-user-drag: inherit;
      }
      .option-item {
        position: absolute;
        -webkit-user-drag: auto;
        z-index: 99;
      }
      .notShow {
        opacity: 0;
      }
      .dispear {
        opacity: 0;
      }
    }

    .hanzi-item {
      width: 40%;
      margin: 0 auto;
      background: #cd4c3f;
      position: absolute;
      bottom: 5%;
      left: 30%;
      padding: 1.5% 0%;
      color: #fff;
      border-radius: 40px;
      .sentence-hanzi,
      .sentence-pinyin {
        text-align: center;
      }
    }
  }
  .lesson-21 {
    .drag-area {
      .option-1 {
        width: 5%;
        height: 6%;
        top: 5%;
        left: 2%;
      }
      .option-2 {
        width: 7%;
        height: 6%;
        top: 6%;
        left: 10%;
      }
      .option-3 {
        width: 6%;
        height: 6%;
        top: 6%;
        left: 20%;
      }
      .option-4 {
        width: 6%;
        height: 6%;
        top: 22%;
        left: 73%;
      }
      .option-5 {
        width: 6%;
        height: 6%;
        top: 22%;
        left: 82%;
      }
      .option-6 {
        width: 6%;
        height: 6%;
        top: 22%;
        left: 90%;
      }
      .option-7 {
        width: 6%;
        height: 6%;
        top: 50%;
        left: 2%;
      }
      .option-8 {
        width: 6%;
        height: 6%;
        top: 50%;
        left: 16%;
      }
      .option-9 {
        width: 6%;
        height: 6%;
        top: 56%;
        left: 80%;
      }
      .option-10 {
        width: 6%;
        height: 6%;
        top: 56%;
        left: 90%;
      }
    }
  }
  .lesson-21 {
    .dropBox {
      // background: #000;
      display: block;
      position: absolute;
      width: 17%;
      height: 28%;
      top: 35%;
      left: 41%;
      z-index: 8;
      display: flex;
      justify-content: center;
      align-items: center;
      div {
        width: 3.5%;
        height: 12%;
        top: 34.5%;
        left: 54.5%;
      }
    }
  }
  .lesson-332 {
    .drag-area {
      .option-1 {
        top: 30%;
        left: 2%;
        width: 10%;
      }
      .option-2 {
        top: 32%;
        left: 15%;
        width: 10%;
      }
      .option-3 {
        width: 10%;
        top: 60%;
        left: 6%;
        width: 10%;
      }
      .option-4 {
        top: 16%;
        left: 74%;
        width: 10%;
      }
      .option-5 {
        top: 16%;
        left: 88%;
        width: 10%;
      }
      .option-6 {
        top: 38%;
        left: 87%;
        width: 10%;
      }
    }
  }
  .lesson-332 {
    .dropBox {
      // background: #000;
      display: block;
      position: absolute;
      width: 13%;
      height: 22%;
      top: 33%;
      left: 43%;
      z-index: 8;
      display: flex;
      justify-content: center;
      align-items: center;
      div {
        width: 3.5%;
        height: 12%;
        top: 44.5%;
        left: 54.5%;
      }
    }
  }
  .lesson-48 {
    .drag-area {
      .option-1 {
        top: 61%;
        left: 86%;
        width: 10%;
      }
      .option-2 {
        top: 66%;
        left: 78%;
        width: 6%;
      }
      .option-4 {
        top: 22%;
        left: 67%;
        width: 10%;
      }
      .option-3 {
        top: 20%;
        left: 83%;
        width: 13%;
      }
      .option-5 {
        top: 33%;
        left: 2%;
        width: 24%;
      }
      .option-7 {
        top: 43%;
        left: 77%;
        width: 4%;
      }
      .option-6 {
        top: 35%;
        left: 85%;
        width: 10%;
      }
    }
  }
  .lesson-48 {
    .dropBox {
      // background: #000;
      display: block;
      position: absolute;
      width: 26%;
      height: 37%;
      top: 18%;
      left: 34%;
      z-index: 8;
      display: flex;
      justify-content: center;
      align-items: center;
      div {
        width: 3.5%;
        height: 12%;
        top: 44.5%;
        left: 54.5%;
      }
    }
  }
  .lesson-61 {
    .drag-area {
      .option-1 {
        top: 39%;
        left: 3%;
        width: 13%;
      }
      .option-2 {
        top: 33%;
        left: 26%;
        width: 10%;
      }
      .option-3 {
        top: 31%;
        left: 73%;
        width: 10%;
      }
      .option-4 {
         top: 12%;
        left: 80%;
        width: 10%;
      }
      .option-5 {
        top: 30.5%;
        left: 86%;
        width: 9%;
      }
      .option-6 {
        top: 37%;
        left: 15%;
        width: 10%;
      }
      .option-7 {
        top: 13.5%;
        left: 64%;
        width: 14%;
      }
    }
  }
  .lesson-61 {
    .dropBox {
      // background: #000;
      display: block;
      position: absolute;
      width: 100%;
      height: 37%;
      top: 60%;
      left: 0%;
      z-index: 8;
      display: flex;
      justify-content: center;
      align-items: center;
      div {
        width: 3.5%;
        height: 12%;
        top: 44.5%;
        left: 54.5%;
      }
    }
  }
}
</style>
