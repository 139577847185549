var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "darg-to-dress-game-container layout-border" },
    [
      _c("PageButton", {
        attrs: {
          bottomCornerButtonList: _vm.bottomCornerButtonList,
          isLastStep: _vm.isLastStep,
        },
        on: { clickNext: _vm.nextPage },
      }),
      _c(
        "div",
        { staticClass: "game-content", class: "lesson-" + _vm.lessonNO },
        [
          _vm.hasStar
            ? _c("Star", {
                attrs: {
                  totalStarNumber: _vm.bgImgList.length,
                  currentIndex: _vm.step,
                },
              })
            : _vm._e(),
          _vm.titleInfo
            ? _c("div", { staticClass: "title-area" }, [
                _c("div", { staticClass: "title" }, [
                  _c(
                    "div",
                    { staticClass: "title-pinyin pinyin font-pinyin-medium" },
                    [_vm._v(" " + _vm._s(_vm.titleInfo.pinyin) + " ")]
                  ),
                  _c("div", { staticClass: "title-hanzi font-hanzi-medium" }, [
                    _vm._v(_vm._s(_vm.titleInfo.hanzi)),
                  ]),
                ]),
              ])
            : _vm._e(),
          _c(
            "div",
            { staticClass: "background-area" },
            [
              _c("img", { staticClass: "bg-img", attrs: { src: _vm.bgImg } }),
              _vm._l(_vm.bgImgList, function (item, index) {
                return [
                  _vm.dropId === item.id ||
                  _vm.dispearIdList.indexOf(item.id) !== -1
                    ? _c("img", {
                        key: index + "bg",
                        staticClass: "bg-img-item",
                        attrs: { src: item.image },
                      })
                    : _vm._e(),
                ]
              }),
            ],
            2
          ),
          _c("div", {
            staticClass: "drop-area dropBox",
            attrs: { draggable: "true" },
            on: {
              drop: function ($event) {
                $event.stopPropagation()
                return _vm.handleDropImg($event)
              },
              dragover: function ($event) {
                $event.stopPropagation()
                return _vm.allowDrop($event)
              },
            },
          }),
          _c(
            "div",
            { staticClass: "drag-area" },
            _vm._l(_vm.optionImgList, function (item, index) {
              return _c(
                "div",
                {
                  key: index + "drag",
                  staticClass: "option-item",
                  class: "option-" + item.id,
                },
                [
                  _c("img", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.dispearIdList.indexOf(item.id) === -1,
                        expression: "dispearIdList.indexOf(item.id) === -1",
                      },
                    ],
                    class: { notShow: _vm.dragId === item.id },
                    attrs: { src: item.image, draggable: "true" },
                    on: {
                      dragstart: function ($event) {
                        $event.stopPropagation()
                        return _vm.handleDragImg($event, item.id)
                      },
                      drop: function ($event) {
                        $event.stopPropagation()
                        return _vm.Sdrop($event)
                      },
                      dragend: function ($event) {
                        $event.stopPropagation()
                        return _vm.dragend(item.group)
                      },
                    },
                  }),
                ]
              )
            }),
            0
          ),
          _vm._l(_vm.bgImgList, function (item, index) {
            return _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.showHnaziIndex === index &&
                      _vm.hasSentence &&
                      item.hanzi,
                    expression:
                      "showHnaziIndex === index && hasSentence&&item.hanzi",
                  },
                ],
                key: index,
                staticClass: "hanzi-item",
              },
              [
                _c(
                  "div",
                  { staticClass: "pinyin sentence-hanzi font-pinyin-medium" },
                  [_vm._v(" " + _vm._s(item.pinyin) + " ")]
                ),
                _c("div", { staticClass: "sentence-hanzi font-hanzi-medium" }, [
                  _vm._v(_vm._s(item.hanzi)),
                ]),
              ]
            )
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }